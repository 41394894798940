<template>
  <div style="margin-top: 20px">
    <b-row>
      <b-col cols="4">
        <b-form-file
            v-model="file"
            :state="Boolean(file)"
            placeholder="Choisissez un fichier ou faites un glisser-déposer"
            drop-placeholder="Déposez votre fichier ici"
            accept=".torrent"
        ></b-form-file>
      </b-col>
      <b-col cols="4">
        <b-button variant="primary" @click="submitFile">Ajouter</b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EbookAddTorrent',
  props: ['id'],
  data: () => ({file: null}),
  methods: {
    ...mapActions({
      addTorrent: 'ebook/addTorrent'
    }),
    submitFile() {
      const payload = {id: this.id, file: this.file}
      this.addTorrent(payload)
        .then(async () => {
          await this.$router.push({name: 'ebook'})
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
