<template>
  <div>
    <b-table striped hover :items="ebooks" :fields="fields" style="margin-top: 20px">
      <template #cell(author)="data">
        {{ data.item.author.name }}
      </template>
      <template #cell(torrent)="data">
        <router-link :to="{name: 'ebookAddTorrent', params: {id: data.item.id}}">
          <font-awesome-icon v-if="data.item.status === 1" icon="fa-solid fa-file-circle-plus" />
        </router-link>
        <font-awesome-icon v-if="data.item.status === 2" icon="fa-solid fa-cloud-arrow-down" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'EbookList',
  data () {
    return {
      fields: [{
        key: 'title',
        label: 'Titre',
      }, {
        key: 'author',
        label: 'Auteur',
      }, {
        key: 'createdDate',
        label: 'Date',
      }, {
        key: 'torrent',
        label: '',
      }],
    }
  },
  computed: {
    ...mapState({
      ebooks: state => state.ebook.ebooks
    })
  },
  methods: {
    ...mapActions({
      getEbooks: 'ebook/getEbooks'
    })
  },
  mounted () {
    this.getEbooks()
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        })
  }
}
</script>
