import axios from 'axios'

const state = {
  configs: [],
  config: null
};

const actions = {
  getConfigs({ commit }) {
    return axios
      .get(process.env.VUE_APP_BASEURL + '/configs')
      .then(response => {
        commit('setConfigs', response.data)
      })
  },
  getConfig({ commit }, id) {
    return axios
      .get(process.env.VUE_APP_BASEURL + '/configs/' + id)
      .then(response => {
        console.log(response.data)
        commit('setConfig', response.data)
      })
  }
};

const mutations = {
  setConfigs(state, data) {
    state.configs = data;
  },
  setConfig(state, data) {
    console.log(data)
    state.config = data;
  },
};

export const config = {
  namespaced: true,
  state,
  actions,
  mutations
};
