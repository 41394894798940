<template>
  <div style="margin-top: 20px">
    <b-form inline @submit="onSubmit">
      <label class="mr-sm-2" for="inline-form-input-value">Valeur :</label>
      <b-form-input
        id="inline-form-input-value"
        class="mb-2 mr-sm-2 mb-sm-0"
        v-model="config.value"
      ></b-form-input>

      <b-button variant="primary">Save</b-button>
    </b-form>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ConfigEdit',
  props: ['id'],
  data: () => ({value: null}),
  computed: {
    ...mapState({
      config: state => state.config.config
    })
  },
  methods: {
    ...mapActions({
      getConfig: 'config/getConfig'
    }),
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.value))
    }
  },
  mounted() {
    this.getConfig(this.id)
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
  }
}
</script>
