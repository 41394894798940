<template>
  <div>
    <b-table striped hover :items="configs" :fields="fields" style="margin-top: 20px">
      <template #cell(edit)="data">
        <router-link :to="{name: 'configEdit', params: {id: data.item.id}}">
          <font-awesome-icon icon="fa-solid fa-pen" />
        </router-link>
      </template>
    </b-table>
  </div>
</template>
  
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'ConfigList',
  data() {
    return {
      fields: [{
        key: 'name',
        label: 'Nom',
      }, {
        key: 'value',
        label: 'Valeur',
      }, {
        key: 'edit',
        label: '',
      }]
    }
  },
  computed: {
    ...mapState({
      configs: state => state.config.configs
    })
  },
  methods: {
    ...mapActions({
      getConfigs: 'config/getConfigs'
    })
  },
  mounted() {
    this.getConfigs()
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.error(error);
      })
  }
}
</script>
  