import Vue from 'vue'
import VueRouter from 'vue-router'
import ConfigList from '../components/config/ConfigList.vue'
import ConfigEdit from '../components/config/ConfigEdit.vue'
import EbookList from '../components/ebook/EbookList.vue'
import EbookAddTorrent from '../components/ebook/EbookAddTorrent.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'ebook'
  },
  {
    path: '/config',
    name: 'config',
    component: ConfigList
  },
  {
    path: '/config/:id',
    name: 'configEdit',
    component: ConfigEdit,
    props: true
  },
  {
    path: '/ebook',
    name: 'ebook',
    component: EbookList
  },
  {
    path: '/ebook/:id/add-torrent',
    name: 'ebookAddTorrent',
    component: EbookAddTorrent,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
