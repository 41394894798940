import Vue from 'vue'
import Vuex from 'vuex'

import { config } from './config.module';
import { ebook } from './ebook.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    config,
    ebook
  }
})
