import axios from 'axios'

const state = {
  ebooks: [],
};

const actions = {
  getEbooks({ commit }) {
    return axios
      .get(process.env.VUE_APP_BASEURL + '/ebooks')
      .then(response => {
        commit('setEbooks', response.data)
      })
  },
  addTorrent(_, { id, file }) {
    const formData = new FormData();
    formData.append('file', file);
    const headers = { 'Content-Type': 'multipart/form-data' };
    return axios.post(process.env.VUE_APP_BASEURL + '/ebooks/' + id + '/add_torrent', formData, { headers });
  },
};

const mutations = {
  setEbooks(state, data) {
    state.ebooks = data;
  },
};

export const ebook = {
  namespaced: true,
  state,
  actions,
  mutations
};
